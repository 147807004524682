<template>
  <b-card>
    <!-- about -->
    <b-card-title> About</b-card-title>
    <b-card-text>{{ aboutData.description }}</b-card-text>
    <b-card-text class="text-left">
      <!-- Point Meta [Free shpping, EMI, etc.] -->
      <ul class="point-features list-unstyled">
        <li v-if="aboutData.estimated_time_of_stay">
          <feather-icon icon="ClockIcon" />
          <span>{{ formatTimeOfStay(aboutData.estimated_time_of_stay) }}</span></li>
        <li>
          <feather-icon icon="CrosshairIcon" />
          <span>{{ aboutData.point_purpose.title }}</span>
        </li>
      </ul>
      <hr>

    </b-card-text>
  </b-card>

</template>

<script>
import { BCard, BCardText, BCardTitle } from 'bootstrap-vue'
import { formatTimeOfStay } from '@/mixins/functions'

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
  },
  mixins: [formatTimeOfStay],
  props: {
    aboutData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
<style scoped>
  .point-features>li>svg {
    margin-right: 0.75rem;
  }
  .point-features>li{
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: bolder;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

</style>
