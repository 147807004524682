import { render, staticRenderFns } from "./PointAbout.vue?vue&type=template&id=19cbb525&scoped=true&"
import script from "./PointAbout.vue?vue&type=script&lang=js&"
export * from "./PointAbout.vue?vue&type=script&lang=js&"
import style0 from "./PointAbout.vue?vue&type=style&index=0&id=19cbb525&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19cbb525",
  null
  
)

export default component.exports