import { postStatuses } from '@/mixins/options'

export const formatTimeOfStay = {
  methods: {
    formatTimeOfStay(timeInMinutes) {
      const hours = Math.floor(timeInMinutes / 60)
      const minutes = timeInMinutes % 60
      if (!hours) {
        return `${minutes} min`
      }
      return `${hours} hrs : ${minutes} min`
    },
  },
}

export const getPostStatusProperty = {
  mixins: [postStatuses],
  methods: {
    getPostStatusProperty(statusSlug, property) {
      const postStatus = this.postStatuses.filter(status => status.slug === statusSlug)
      return postStatus[0][property]
    },
  },
}

export const getUserAvatarText = {
  methods: {
    getUserAvatarText(UserName) {
      return UserName.substring(0, 1).toUpperCase()
    },
  },
}
export const _ = null
