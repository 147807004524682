<template>
  <b-card
    class="point-header mb-2"
    :img-src="getMainImage(headerData)"
    img-top
    alt="cover photo"
    body-class="p-0"
  >
    <!-- point picture -->
    <div class="position-relative">
      <div class="point-img-container d-flex align-items-center">
        <div class="point-img">
          <GmapMap
            :center="{
              lat: headerData.lat,
              lng: headerData.lng,
            }"
            :zoom="8"
            style="width:150px;  height: 150px"
          >
            <GmapMarker
              :position="{
                lat: headerData.lat,
                lng: headerData.lng,
              }"
            />
          </GmapMap>

        </div>
        <!-- point title -->
        <div class="point-title ml-3">
          <h2 class="text-white">
            {{ headerData.name }}
          </h2>
          <p class="text-white">
            <b-media no-body>
              <b-media-aside
                vertical-align="center"
                class="mr-50"
              >
                <b-avatar
                  v-if="'avatar'in headerData"
                  href="javascript:void(0)"
                  size="24"
                  :src="headerData.user.avatar"
                />
                <b-avatar
                  v-if="!('avatar'in headerData)"
                  button
                  size="24"
                  variant="dark"
                  :text="getUserAvatarText(headerData.user.name)"
                  class="align-baseline"
                />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link class="text-body">{{ headerData.user.name }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">Jan 10, 2020</small>
              </b-media-body>
            </b-media>
          </p>
        </div>

        <!--/ point title -->
      </div>
    </div>
    <!--/ point picture -->

    <!-- point navbar -->
    <div class="point-header-nav">
      <b-navbar
        toggleable="md"
        type="light"
      >
        <!-- toggle button -->
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>
        <!--/ toggle button -->

        <!-- collapse -->
        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="point-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <!-- edit buttons -->
            <template #tabs-end>

              <div class="ml-auto">
                <span class="post-status">
                  <b-badge
                    :variant="`light-${getPostStatusProperty(headerData.post_status, 'class')}`"
                  >
                    <feather-icon
                      :icon="getPostStatusProperty(headerData.post_status, 'icon')"
                      class="mr-25"
                    />
                    <span>{{ headerData.post_status }}</span>
                  </b-badge>
                </span>
                <b-button
                  v-if="ability.can('update', headerData)"
                  variant="primary"
                  @click="$parent.handleEditPointActionClick(headerData)"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="d-block d-md-none"
                  />
                  <span class="font-weight-bold d-none d-md-block">
                    Edit
                  </span>
                </b-button>
              </div>

            </template>
            <!-- edit buttons -->
          </b-tabs>
        </b-collapse>
        <!--/ collapse -->
      </b-navbar>
    </div>
    <!--/ point navbar -->
  </b-card>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BBadge,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import defineAbilityFor from '@/libs/acl/defineAbility'
import { getPostStatusProperty, getUserAvatarText } from '@/mixins/functions'
import defaultImage from '@/assets/images/pages/no-photo-available.png'

export default {
  components: {
    BCard,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
    BBadge,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  mixins: [getPostStatusProperty, getUserAvatarText],
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getMainImage(headerData) {
      if (headerData.main_image !== null && 'url' in headerData.main_image) {
        return headerData.main_image.url
      }
      return defaultImage
    },
  },
  setup() {
    const user = store.getters['account/user']
    const ability = defineAbilityFor(user)
    return {
      ability,
    }
  },
}

</script>
<style scoped>
  .point-header>img {
    max-height: 20em;
    object-fit: cover;
  }
  #point-details .point-header .point-img-container .point-img {
    height: auto;
    width: auto;
  }
  .point-latest-img>a>img{
    height: 100%;
    width: 100%;
  }
  .post-status{
    padding-left: 1em;
    padding-right: 1em;
  }
</style>
