<template>
  <div
    id="point-details"
  >
    <!-- Alert: No point found -->
    <b-alert
      variant="danger"
      :show="point === undefined"
    >
      <h4 class="alert-heading">
        Error fetching point data
      </h4>
      <div class="alert-body">
        No point found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-points-list'}"
        >
          Points List
        </b-link>
        for other points.
      </div>
    </b-alert>
    <div v-if="point">
      <point-header :header-data="point" />
      <!-- point info  -->
      <section id="point-info">
        <b-row>
          <b-col
            lg="3"
            cols="12"
            order="1"
            order-md="1"
          >
            <point-about :about-data="point" />
            <point-suggested-pages :pages-data="pointData.suggestedPages" />
          </b-col>

          <!-- routes -->
          <b-col
            lg="6"
            cols="12"
            order="2"
            order-md="2"
          >
            <point-route :routes="point.media_files" />
          </b-col>
          <!--/ routes -->

          <!-- latest media suggestion and polls -->
          <b-col
            lg="3"
            cols="12"
            order="3"
          >
            <point-latest-media
              v-if="mediaFiles"
              :latest-images="mediaFiles"
            />
            <!--            <point-suggestion :suggestions="point" />-->
            <!--            <point-polls :polls-data="point" />-->
          </b-col>
          <!--/ latest media suggestion and polls -->

          <!-- load more  -->
          <b-col
            cols="12"
            order="4"
          >
            <point-bottom />
          </b-col>
          <!--/ load more  -->
        </b-row>
      </section>
      <!--/ point info  -->
    </div>

  </div>
</template>

<script>
import { BRow, BCol, BAlert } from 'bootstrap-vue'

import { usePointsUi } from '@/views/apps/point/usePoints'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import store from '@/store'
import PointHeader from './PointHeader.vue'
import PointAbout from './PointAbout.vue'
import PointSuggestedPages from './PointSuggestedPages.vue'
import PointRoute from './PointRoute.vue'
import PointLatestMedia from './PointLatestMedia.vue'
import PointSuggestion from './PointSuggestion.vue'
import PointPolls from './PointPolls.vue'
import PointBottom from './PointBottom.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,

    PointHeader,
    PointAbout,
    PointSuggestedPages,
    PointRoute,
    PointLatestMedia,
    PointSuggestion,
    PointPolls,
    PointBottom,
  },
  data() {
    return {
      pointData: { },
    }
  },
  // created() {
  //   this.$http.get('/profile/data').then(res => { this.profileData = res.data })
  // },
  setup() {
    const {
      handleCartActionClick, togglePointInWishlist, resolvePointTypeBadgeVariant, handleEditPointActionClick,
    } = usePointsUi()

    const point = ref(null)
    const mediaFiles = ref([])

    // Remote Data
    const fetchPoint = () => {
      // Get point  id from URL
      const { route } = useRouter()
      const pointId = route.value.params.id
      store.dispatch('point/fetchPoint', { id: pointId })
        .then(response => {
          point.value = store.getters['point/point']
          response.data.data.media_files.forEach(file => {
            mediaFiles.value.push(file.url)
          })
        })
        .catch(error => {
          if (error.response.status === 404) {
            point.value = undefined
          }
        })
    }

    // UI
    const selectedColor = ref(null)

    fetchPoint()

    return {

      // Fetched Point
      point,
      mediaFiles,

      // UI
      selectedColor,
      handleCartActionClick,
      resolvePointTypeBadgeVariant,
      handleEditPointActionClick,
      togglePointInWishlist,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-point.scss';
</style>
