<template>
  <b-card>
    <b-card-title> Latest Media</b-card-title>
    <b-card-text>
      <gallery
        :images="latestImages"
        :index="mediaFileIndex"
        @close="mediaFileIndex = null"
      />
      <div
        v-for="(image, imageIndex) in latestImages"
        :key="imageIndex"
        class="image"
        :style="{ backgroundImage: 'url(' + image + ')', width: '10em', height: '150px' }"
        @click="mediaFileIndex = imageIndex"
      />
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VueGallery from 'vue-gallery'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BCardTitle,
    BCardText,
    gallery: VueGallery,
  },
  props: {
    latestImages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mediaFileIndex: null,
    }
  },
}
</script>
<style scoped>
  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
  }
</style>
